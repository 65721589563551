import PopUp from "./popup";
import axios from "axios";
import { config } from "../config";
import { getToken, isAuthenticated, tokenStore, logout } from "../States/Token";
import { currentTimeInMilliSecs } from "./Time";
import "../polyfills";
import React from "react";
import ReactDOM from "react-dom";
import configureStore from "../config/configureStore";
import { Provider } from "hooks-for-redux";
import "../assets/base.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { Detector } from "react-detect-offline";

const store = configureStore();
var showing_popup = false;

const renderApp = (msg, err, callBack) => {
  showing_popup = true;
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Detector
          render={({ online }) => (
            <div>
              {!online && (
                <PopUp
                  msg="It seems that you are offline. Kindly refresh this page after a successful network connection."
                  error="No Internet!"
                  onClick={callBack}
                />
              )}
              {online && <PopUp msg={msg} error={err} onClick={callBack} />}
            </div>
          )}
        />
      </Router>
    </Provider>,
    document.getElementById("root")
  );
};

function isConnected() {
  return window.navigator.onLine;
}

function isSessionExpired() {
  return (
    getToken()["token"] && getToken()["expire_at"] < currentTimeInMilliSecs()
  );
}

function handleError(onFailure, error) {
  if (showing_popup) {
    return;
  }
  if (!isConnected()) {
    renderApp(
      "It seems that you are offline. Kindly refresh this page after a successful network connection.",
      "No Internet!",
      () => {
        showing_popup = false;
      }
    );
  } else if (isSessionExpired()) {
    renderApp(
      "It seems that your session expired. Please reload the page.",
      "Session Expired",
      () => {
        showing_popup = false;
      }
    );
  }
  // else if (!error.response && error.request) {
  //   renderApp("We are not able to reach our servers. Please check your network and reload the page to try again.", "Server Error", () => {showing_popup=false});
  // }
  else {
    onFailure(error);
  }
}

export function authorizationHeader() {
  if (isAuthenticated()) {
    return {
      Authorization: "Bearer " + getToken()["token"],
      referrer: window.location.href,
    };
  } else {
    return {};
  }
}

export function getCall({
  url = config.url.BASE,
  path = "",
  params = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.params = params;
  reqConfig.headers = authorizationHeader();
  return axios
    .get(sanitizeUrl(url + "/" + path), reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function getCallWithParams({
  url = config.url.BASE,
  path = "",
  params = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.params = params;
  reqConfig.headers = authorizationHeader();
  return axios
    .get(sanitizeUrl(url + "/" + path), reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function postCall({
  url = config.url.BASE,
  path = "",
  data = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  return axios
    .post(sanitizeUrl(url + "/" + path), data, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function deleteCall({
  url = config.url.BASE,
  path = "",
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  return axios
    .delete(sanitizeUrl(url + "/" + path), reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function putCall({
  url = config.url.BASE,
  path = "",
  data = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  return axios
    .put(sanitizeUrl(url + "/" + path), data, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function uploadFiles({
  url = config.url.BASE,
  path = "",
  files = [],
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  var formData = new FormData();
  files.map((file) => formData.append("files", file));
  return axios
    .post(sanitizeUrl(url + "/" + path), formData, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function uploadBlob({
  url = config.url.BASE,
  path = "",
  blob = {},
  fileName = "",
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  var formData = new FormData();
  formData.append("files", blob, fileName);
  // formData.append('data', blob);
  return axios
    .post(sanitizeUrl(url + "/" + path), formData, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function fetchBlob(uri) {
  fetch(uri, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  }).then((r) => {
    r.blob().then(function(myBlob) {
      downloadFile(myBlob, "recording.mp4", "application/zip");
    });
  }).catch((r) => {
    bottomAlert("File not available.", "warning");
    this.setState({show_overlay: false});
  }).finally(() => {
    this.setState({show_overlay: false});
  });
}

export function downloadFile(data, fileName, type = "text/plain") {
  // Create an invisible A element
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(new Blob([data], { type }));

  // Use download attribute to set set desired file name
  a.setAttribute("download", fileName);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

export function downloadText(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function sanitizeUrl(url) {
  var sanitized_url = url.replace(/([^:]\/)\/+/g, "$1").replace(/\/+$/, "");
  if (sanitized_url.split("/").length <= 4) {
    // fall back for "/" paths on FastAPI backend
    return sanitized_url + "/";
  } else {
    return sanitized_url;
  }
}

export function loadJavascript({ script_url, id, onLoad }) {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = script_url;
    script.id = id;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      return onLoad && onLoad();
    };
  } else {
    return onLoad && onLoad();
  }
}