import React from 'react';
import { RRule, rrulestr } from 'rrule';
import { Fragment } from 'react';
import moment from 'moment';

export var currentTimeInSecs = function () { return Math.floor(new Date().getTime() / 1000) };

export var currentTimeInMilliSecs = function () { return new Date().getTime() }; // UTC

export var currentTimeInFloatSecs = function () { return new Date().getTime() / 1000 };

export var msToMinutes = function (ms) { if (ms) { return parseInt(ms / 1000 / 60) } else { return 0 } };

export var msToSeconds = function (ms) { if (ms) { return parseInt(ms / 1000) } else { return 0 } };

export var dateFromMeetingObj = function (meeting) { var date = Date.parse(meeting.date + " " + meeting.time); return date }

export var msToDateObject = function (ms) {
	return new Date(parseInt(ms))
}

export var msToLongDateString = function (ms) {
	if(ms === -1) {
		return "Never";
	}
	return (new Date(parseInt(ms)).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })) + " " +
		new Date(parseInt(ms)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })
};

export var msToShortDateString = function (ms) {
	return (new Date(parseInt(ms)).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })) + " " +
		new Date(parseInt(ms)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})
};

export var msToTimeString = function (ms) {
	return new Date(parseInt(ms)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})
};

export var msToDateString = function (ms) {
	return new Date(parseInt(ms)).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })
};

export var msToTimezoneDateString = function (ms, timezone) {
	return (new Date(parseInt(ms)).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', timeZone: timezone })) + " " +
		new Date(parseInt(ms)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: timezone, timeZoneName: 'short' })
}

export var timeFromNow = function (time) {
	// Setup return object
	var tfn = {};

	if (!time) {
		tfn.strRepr = "Unknown";
		tfn.when = "";
		tfn.unitOfTime = "";
		return tfn;
	}

	// Get timestamps
	var unixTime = new Date(time).getTime();
	if (!unixTime) return;
	var now = new Date().getTime();

	// Calculate difference
	var difference = (unixTime / 1000) - (now / 1000);

	// Check if time is in the past, present, or future
	tfn.when = 'now';
	if (difference > 0) {
		tfn.when = 'future';
	} else if (difference < -1) {
		tfn.when = 'past';
	}

	// Convert difference to absolute
	difference = Math.abs(difference);

	// Calculate time unit
	if (difference / (60 * 60 * 24 * 365) > 1) {
		// Years
		tfn.unitOfTime = 'yrs';
		tfn.time = Math.floor(difference / (60 * 60 * 24 * 365));
	} else if (difference / (60 * 60 * 24 * 45) > 1) {
		// Months
		tfn.unitOfTime = 'mths';
		tfn.time = Math.floor(difference / (60 * 60 * 24 * 30));
	} else if (difference / (60 * 60 * 24) > 1) {
		// Days
		tfn.unitOfTime = 'days';
		tfn.time = Math.floor(difference / (60 * 60 * 24));
	} else if (difference / (60 * 60) > 1) {
		// Hours
		tfn.unitOfTime = 'hrs';
		tfn.time = Math.floor(difference / (60 * 60));
	} else if (difference / 60 > 1) {
		// Minutes
		tfn.unitOfTime = 'mins';
		tfn.time = Math.floor(difference / 60);
	} else {
		// Seconds
		tfn.unitOfTime = 'secs';
		tfn.time = Math.floor(difference);
	}

	if (tfn.when == "past") {
		tfn.strRepr = tfn.time + " " + tfn.unitOfTime + " ago";
	} else if (tfn.when == "future") {
		tfn.strRepr = "in " + tfn.time + " " + tfn.unitOfTime;
	} else {
		tfn.strRepr = "Now";
	}
	if (tfn.unitOfTime == 'secs') {
		tfn.strRepr = "Now";
	}
	// Return time from now data
	return tfn;
};

export function rrule_string_to_human_readable(rrule_string) {
	if (!rrule_string) {
		return null;
	}
	const rule = rrulestr(rrule_string);
	return rule.toText();
}

export function roundToNearest15(date = new Date()) {
  const minutes = 15;
  const ms = 1000 * 60 * minutes;

  // 👇️ replace Math.round with Math.ceil to always round UP
  return new Date(Math.ceil(date.getTime() / ms) * ms);
}

export function rrule_string_to_instances(rrule_string, timezone, end_time) {
	if (!rrule_string) {
		return [];
	}
	try{
		var elements = [];
		const rule = rrulestr(rrule_string);
		rule.all().forEach((element, index) => {
			if(!element.toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', timeZone: timezone } ).toLowerCase().includes("invalid date")){
				if (element.getTime() <= end_time || end_time == 0) {
					elements.push(
						<span key={index}>{
							(element.toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', timeZone: timezone } )) + " " +
							element.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short', timeZone: timezone })
						}<br/></span>
					);
				}
			}
		});
		if (elements.length > 0){
			return (
				<Fragment>
					Occurrances:<br/>
					{elements}
				</Fragment>
			);
		} else {
			return "";
		}
	} catch (error) {
		console.log(error);
		return [];
	}
}

export function get_rrule_dtstart(meeting) {
	return new RRule({dtstart: moment(dateFromMeetingObj(meeting)).toDate(), tzid: meeting.timezone}).toString();
}

export function corrected_rrule(meeting) {
	var old_rrule = meeting.rrule;
	var dtstart = get_rrule_dtstart(meeting);

	var rrule_repeat = old_rrule.split("\n")[1];

	if(!old_rrule.includes(dtstart) && rrule_repeat) {
		return dtstart + "\n" + rrule_repeat;
	} else {
		return old_rrule;
	}
}

export function noDelaySetInterval(func, interval) { 
	func(); 
	return setInterval(func, interval); 
}

export function msToMinuteSec(ms) {
	const minutes = msToMinutes(ms);
	const overage = ms - (minutes * 60 * 1000);
	const seconds = msToSeconds(overage);

	var timestring = "";
	if (minutes > 0) {
		timestring += (minutes + (minutes > 1 ? " mins" : " min"));
	}
	if (seconds > 0) {
		timestring += ((minutes > 0 ? " " : "") + seconds + (seconds > 1 ? " secs" : " sec"));
	}
	return timestring;
}

export function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export function oneSecond() {
	return 1000;
}

export function oneMinute() {
	return oneSecond() * 60;
}

export function oneHour() {
	return oneMinute() * 60;
}

export function oneDay() {
	return oneHour() * 24;
}

export function oneWeek() {
	return oneDay() * 7;
}

export function oneMonth() {
	return oneDay() * 30;
}

export function oneQuarter() {
	return oneMonth() * 3;
}

export function oneYear() {
	return 365 * oneDay();
}

export function addYears(dt, n) {
	return new Date(dt.setFullYear(dt.getFullYear() + n));
}