// const BASE_URL = "https://platform-stg.saleassist.ai";
// const BASE_URL = "https://platform.saleassist.ai";
const BASE_URL = "https://platform-stg.saleassist.ai";

// const SOCKET_URL = "http://localhost:5050";
const SOCKET_URL = "https://sockets-stg.saleassist.ai";

const colors = {
  saleassist: "#3684F2",
  primary: "#545cd8",
  secondary: "#6c757d",
  success: "#4BBF73",
  info: "#1F9BCF",
  warning: "#f0ad4e",
  danger: "#d9534f",
  dark: "#495057",
  focus: "#444054",
  alternate: "#83588a",
  light: "#f8f9fa",
};

const url = {
  BASE: BASE_URL,
  SOCKET_URL: SOCKET_URL,
  API_KEYS: BASE_URL + "/api_keys",
  ADMIN: BASE_URL + "/admin",
  ADDONS: BASE_URL + "/addons",
  ASYNC_COMMS: BASE_URL + "/async_comms",
  AUTH: BASE_URL + "/auth",
  BILLING: BASE_URL + "/billing",
  CAMPAIGN: BASE_URL + "/campaign",
  CAMPAIGN_CONTACTS: BASE_URL + "/campaign_contacts",
  CAMPAIGN_TEMPLATE: BASE_URL + "/campaign_template",
  CHANNELS: BASE_URL + "/channels",
  CLIENT_USER_MAPPINGS: BASE_URL + "/client_user_mappings",
  CLIENTS: BASE_URL + "/clients",
  CONTACTS: BASE_URL + "/contacts",
  CONSUMPTION_LOGS: BASE_URL + "/consumption_logs",
  COUPONS: BASE_URL + "/coupons",
  COUPON_USAGE: BASE_URL + "/coupon_usage",
  COMMISSIONS: BASE_URL + "/commissions",
  CONVERSATIONS: BASE_URL + "/conversations",
  EVENTS: BASE_URL + "/events",
  FORMS: BASE_URL + "/forms",
  GROUPS: BASE_URL + "/groups",
  GROUPS_USER_MAPPING: BASE_URL + "/groups_user_mapping",
  GROUPS_ROLE_MAPPING: BASE_URL + "/groups_role_mapping",
  AI_VIDEO: BASE_URL + "/ai_video",
  LIVE_CALLS: BASE_URL + "/live_calls",
  MEDIA: BASE_URL + "/media",
  MEETING_TOKENS: BASE_URL + "/meeting_tokens",
  MEETINGS: BASE_URL + "/meetings",
  NOTIFICATIONS: BASE_URL + "/notifications",
  PAYMENT_PLANS: BASE_URL + "/payment_plans",
  PAYMENTS: BASE_URL + "/payments",
  PLANS: BASE_URL + "/plans",
  PERMISSIONS: BASE_URL + "/permissions",
  PEOPLE: BASE_URL + "/people",
  PRODUCTS: BASE_URL + "/products",
  ROLES: BASE_URL + "/roles",
  ROLE_USER_MAPPING: BASE_URL + "/roles_user_mapping",
  SOURCES: BASE_URL + "/sources",
  SOURCE: BASE_URL + "/source",
  STORAGE: BASE_URL + "/storage",
  TRACKER: BASE_URL + "/tracker",
  USERS: BASE_URL + "/users",
  WEBHOOKS: BASE_URL + "/webhooks",
  ACTIONS: BASE_URL + "/actions",
  ALL_REPORT: BASE_URL + "/report",
  GET_REPORT_CSV: BASE_URL + "/report/csv",
  CREATE_WORKFLOW: BASE_URL + "/video_workflows/create-workflow",
  GET_WORKFLOW: BASE_URL + "/video_workflows/workflow",
  SEND_OTP_ON_EMAIL: BASE_URL + "/auth/send-otp-on-email",
  LOGIN_WITH_EMAIL_OTP: BASE_URL + "/auth/login-with-email-otp",
  CAMPAIGN_OFFSET: BASE_URL + "/campaign/get_campaign_offset/1/1000",
  CAMPAIGN_DETAIL: BASE_URL + "/campaign",
  AI_VIDEO_CREATE: BASE_URL + "/ai-video/create",
  SMTP: BASE_URL + "/smtp",
  SHOPPABLE_VIDEO: BASE_URL + "/shoppable-video",
};

const meeting_experiences = {
  conference: {
    owner: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
  },
  webinar: {
    owner: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: false,
      can_speak: false,
      can_screen_share: false,
      can_collaborate: false,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: false,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: false,
      can_speak: false,
      can_screen_share: false,
      can_collaborate: false,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: false,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
  },
  live_class: {
    owner: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
  },
  live_streaming: {
    owner: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: true,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: false,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: false,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: false,
      is_video_on: true,
      is_audio_on: true,
    },
  },
  audio_call: {
    owner: {
      can_show_camera: false,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: false,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: false,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: false,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
  },
  talk_to_me: {
    owner: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
  },
  live_call: {
    owner: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
  },
  direct_call: {
    owner: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
  },
  outbound_call: {
    owner: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: true,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
  },
  all: {
    owner: {
      can_show_camera: false,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    moderator: {
      can_show_camera: false,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: true,
      can_record: true,
      can_stream: false,
      can_video_share: true,
      can_start_meeting: true,
      can_end_meeting: true,
      can_kick_out: true,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: true,
      can_secure: true,
      can_see_stats: false,
      can_invite: true,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    participant: {
      can_show_camera: false,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
    public: {
      can_show_camera: false,
      can_speak: true,
      can_screen_share: true,
      can_collaborate: true,
      can_document_share: false,
      can_record: false,
      can_stream: false,
      can_video_share: false,
      can_start_meeting: false,
      can_end_meeting: false,
      can_kick_out: false,
      can_chat: true,
      can_raise_hand: true,
      can_mute_everyone: false,
      can_secure: true,
      can_see_stats: false,
      can_invite: false,
      can_co_browse: true,
      is_video_on: true,
      is_audio_on: true,
    },
  },
};

const dev = {
  url: url,
  landing_page: "/workspace/admin",
  default_meeting_domain: "localhost:3001",
  colors: colors,
  razorpay_key_id: "rzp_test_6qMDYgeHkcDSNX",
  meeting_experiences: meeting_experiences,
};

const prod = {
  url: url,
  landing_page: "/workspace/admin",
  default_meeting_domain: "meetings-stg.saleassist.ai",
  colors: colors,
  razorpay_key_id: "rzp_live_8JXSK0ZDSlsNqI",
  meeting_experiences: meeting_experiences,
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
