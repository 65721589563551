import { createReduxModule } from "hooks-for-redux";
import { config } from "../config";

export const [meeting_experiences, {setExperiences, resetExperiences}, experiencesStore] = createReduxModule(
  "meeting_experiences",
  {},
  {
    setExperiences:(meeting_experiences, experiencesObject) => {meeting_experiences = experiencesObject; return meeting_experiences},
    resetExperiences:(meeting_experiences) => {meeting_experiences = {}; return meeting_experiences}
  }
);

export function getExperiences() {
  return config.meeting_experiences;
}

export function getMeetingExperience(meeting_type) {
  return getExperiences()[meeting_type];
}

export function getUserExperience(meeting_type, user_type) {
  // console.log(meeting_type, user_type);
  return getMeetingExperience(meeting_type)[user_type];
}
